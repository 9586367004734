import { useCallback, useState } from 'react';
import { parse } from 'cookie';
import snakecaseKeys from 'snakecase-keys';
import camelcaseKeys from 'camelcase-keys';

import { FetchError, HttpError } from '../utils/errors';

export default function useFetch() {
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const request = useCallback(async (url, method, body, options = {}) => {
    const { csrftoken } = parse(document.cookie);

    let bodyPayload = null;
    if (body) {
      const snakecasedBody = snakecaseKeys(body, { deep: true });
      bodyPayload = JSON.stringify(snakecasedBody);
    }
    setLoading(true);
    const res = await fetch(url, {
      method,
      body: bodyPayload,
      headers: {
        'X-CSRFToken': csrftoken,
      },
      ...options,
    });
    setLoading(false);
    setLoaded(false);
    if (res.status !== 200) {
      throw new HttpError(res);
    }

    const json = camelcaseKeys(await res.json(), { deep: true });

    if (!json.success) {
      throw new FetchError(json);
    }

    return json;
  }, []);

  return { request, loaded, loading };
}
