import React from 'react';
import { matchPath, useParams, Route, Switch } from 'react-router-dom';

import ClientTemplate from 'components/templates/ClientTemplate';
import ClientCard from 'components/ClientCard';
import { useThrowableHttpGet } from 'hooks/useThrowableHttp';
import Client from './Client';
import QQLogin from './QQLogin';

function WebClient({ match }) {
  const { clientId } = useParams();
  const { response: client } = useThrowableHttpGet(`/api/v2/clients/${clientId}/`);
  return (
    client && (
      <ClientTemplate>
        <ClientCard>
          <Switch>
            <Route path={`${match.path}/login/`} exact render={() => <Client client={client} />} />
            <Route path={`${match.path}/custom/qq/`} exact render={() => <QQLogin client={client} />} />
          </Switch>
        </ClientCard>
      </ClientTemplate>
    )
  );
}

WebClient.propTypes = {
  match: matchPath,
};

export default WebClient;
