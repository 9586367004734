import { useHttpGet, useHttpPost } from './useHttp';

const throwIfError = ({ error, ...rest }) => {
  if (error) throw error;
  return rest;
};

const useThrowableHttpGet = (...args) => throwIfError(useHttpGet(...args));

const useThrowableHttpPost = (...args) => throwIfError(useHttpPost(...args));

export { useThrowableHttpGet, useThrowableHttpPost };
