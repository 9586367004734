import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import ThemeProvider, { palette } from '@seaweb/coral/components/ThemeProvider';
import GeneralErrorBoundary from 'components/boundaries/GeneralErrorBoundary';
import Pusher from '@seaweb/coral/components/Pusher';
import 'i18n';
import NotFound404 from 'pages/NotFound404';
import WebClient from 'pages/WebClient';

const fontFamily = "'Open Sans', sans-serif";

const GlobalStyle = createGlobalStyle`
  html, body, #root {
    max-width: 100%;
    height: 100%;
    overflow-x: hidden;
  }

  body {
    font-family: ${fontFamily};
    font-size: 14px;
    margin: 0;
  }

  a {
    text-decoration: none;
    color: ${palette.black};

    &:focus {
      color: ${palette.blue};
    }
  }

  button {
    cursor: pointer;
  }
`;
const StyledPusher = styled(Pusher)`
  z-index: 100;
`;

const seaAuthTheme = {};

ReactDOM.render(
  <BrowserRouter>
    <StyledPusher>
      <ThemeProvider theme={seaAuthTheme}>
        <GlobalStyle />
        <GeneralErrorBoundary>
          <Switch>
            <Route path="/web/clients/:clientId" component={WebClient} />
            <Route render={NotFound404} />
          </Switch>
        </GeneralErrorBoundary>
      </ThemeProvider>
    </StyledPusher>
  </BrowserRouter>,
  document.getElementById('root')
);
