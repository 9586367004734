import { Component } from 'react';
import { func, node } from 'prop-types';

class ErrorBoundary extends Component {
  state = {
    error: null,
    info: null,
  };

  componentDidCatch(error, info) {
    const { onError } = this.props;
    if (typeof onError === 'function') {
      try {
        onError.call(this, error, info.componentStack);
      } catch (ignoredError) {
        /* ignore */
      }
    }
    this.setState({ error, info });
  }

  resetError = () => {
    this.setState({ error: null, info: null });
  };

  render() {
    const { children, renderFallback } = this.props;
    const { error, info } = this.state;
    if (error !== null) {
      return renderFallback({
        componentStack: info.componentStack,
        error,
        resetError: this.resetError,
      });
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  onError: func,
  renderFallback: func,
  children: node,
};

export default ErrorBoundary;
