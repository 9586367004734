import React from 'react';
import styled from 'styled-components';
import ClientTemplate from 'components/templates/ClientTemplate';

const StyledContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
`;

const StyledContent = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

function NotFound404() {
  return (
    <ClientTemplate>
      <StyledContainer>
        <StyledContent>
          <h1>404</h1>
          <p>Not Found</p>
        </StyledContent>
      </StyledContainer>
    </ClientTemplate>
  );
}

export default NotFound404;
