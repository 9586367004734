import React from 'react';
import { useTranslation } from 'react-i18next';
import Field from '@seaweb/coral/components/Field';
import Dialog, { DialogTitle, DialogClose, DialogContent, DialogActions } from '@seaweb/coral/components/Dialog';
import Button from '@seaweb/coral/components/Button';
import { bool, func, object } from 'prop-types';
import styled from 'styled-components';

const StyledDialog = styled(Dialog)`
  width: 300px;
  max-width: 90%;
`;

function DetailDialog({ isOpen, closeDialog, client }) {
  const { t } = useTranslation();
  return (
    <StyledDialog isOpen={isOpen} onClose={closeDialog}>
      <DialogTitle>
        {t('client.detailDialog.title')}
        <DialogClose />
      </DialogTitle>
      <DialogContent>
        <Field label={t('client.detailDialog.clientName')}>
          <span>{client.name}</span>
        </Field>
        <Field label={t('client.detailDialog.email')}>
          <a href={`mailto:${client.owner}`}>{client.owner}</a>
        </Field>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>{t('client.detailDialog.okBtnTitle')}</Button>
      </DialogActions>
    </StyledDialog>
  );
}

DetailDialog.propTypes = {
  isOpen: bool,
  closeDialog: func,
  client: object,
};

export default DetailDialog;
