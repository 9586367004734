import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Select, { SelectButton, SelectList, SelectOption } from '@seaweb/coral/components/Select';
import Button from '@seaweb/coral/components/Button';
import { node } from 'prop-types';
import usFlag from 'images/flags/us.png';
import vnFlag from 'images/flags/vn.png';

const StyledContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
`;

const StyledCardContainer = styled.div`
  position: relative;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  border-radius: 2px;
  border: solid 1px #e3e8f6;
  background-color: #fff;
`;

const StyledCardFooter = styled.section`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
`;

const StyledSelectButton = styled(SelectButton)`
  width: 160px;
`;

const StyledOptionContainer = styled.div`
  display: flex;
  align-items: center;
  > *:not(:first-child) {
    margin-left: 16px;
  }
`;

function ClientCard({ children }) {
  const { t, i18n } = useTranslation();
  return (
    <StyledContainer>
      <StyledCardContainer>
        {children}
        <StyledCardFooter>
          <Select value={i18n.language} onChange={v => i18n.changeLanguage(v)}>
            <StyledSelectButton />
            <SelectList>
              <SelectOption value="en">
                <StyledOptionContainer>
                  <img src={usFlag} alt="US Flag" height="16" />
                  <span>English</span>
                </StyledOptionContainer>
              </SelectOption>
              <SelectOption value="vi">
                <StyledOptionContainer>
                  <img src={vnFlag} alt="US Flag" height="16" />
                  <span>Việt Nam</span>
                </StyledOptionContainer>
              </SelectOption>
            </SelectList>
          </Select>
          <Button variant="text" as="a" href="/docs/" target="_blank">
            {t('client.help')}
          </Button>
        </StyledCardFooter>
      </StyledCardContainer>
    </StyledContainer>
  );
}

ClientCard.propTypes = {
  children: node.isRequired,
};

export default ClientCard;
