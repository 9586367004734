import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import MErrorBoundary from './MErrorBoundary';
import LoginButtons from './LoginButtons';
import Container, { StyledCardTitle } from './Container';
import DetailDialog from './DetailDialog';
import { clientPropTypes } from './propTypes';

const StyledDescription = styled.p`
  margin: 10px 0 0 0;
  text-align: center;
`;

const StyledButton = styled.button`
  background: transparent;
  font-weight: 600;
  font-size: 14px;
  border: none;
`;

function Body({ client }) {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  return (
    <StyledDescription>
      {t('client.cardDescription')}
      <br />
      <StyledButton onClick={() => setIsOpen(true)}>{client.name}</StyledButton>
      <DetailDialog isOpen={isOpen} closeDialog={() => setIsOpen(false)} client={client} />
    </StyledDescription>
  );
}

Body.propTypes = {
  client: clientPropTypes.isRequired,
};

function Client({ client }) {
  const { t } = useTranslation();
  return (
    <Container>
      <MErrorBoundary>
        <StyledCardTitle>{t('welcome')}</StyledCardTitle>
        <Body client={client} />
        <LoginButtons client={client} />
      </MErrorBoundary>
    </Container>
  );
}

Client.propTypes = {
  client: clientPropTypes.isRequired,
};

export default Client;
