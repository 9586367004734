import { useState, useRef, useEffect } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import snakeCase from 'lodash/snakeCase';
import useEventCallback from '@seaweb/coral/hooks/useEventCallback';
import useFetch from './useFetch';

/**
 * @param {{ [key: string]: string|number }} params
 */
export const joinParams = params =>
  Object.keys(params)
    .map(k => `${encodeURIComponent(snakeCase(k))}=${encodeURIComponent(params[k])}`)
    .join('&');

/**
 * Declarative get request - similar to Relay GraphQL.
 *
 * Normally, adding new option is not encouraged because most
 * of the times it leads to too many cases.
 * However, `skip` option is very important because there's no
 * way to prevent request being sent unless:
 *
 * - You don't mount the component - may be too restrictive
 *   especially for animation.
 *
 * - Get around with not updating state - this is of course
 *   a really bad solution because it complicates the logic
 *   and will still request at least once on mount.
 *
 * @param {string} url
 * @param {String} method
 * @param {Object} params
 * @param {Object} body
 * @param {{ skip?: boolean }} options
 */
const useHttp = (url, method, params, body, options = {}) => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const { request, loaded, loading } = useFetch();

  // abort is called if and only if it's unmounted
  const abortRef = useRef(new AbortController());
  useEffect(() => () => abortRef.current.abort(), []);

  const fetch = useEventCallback(async () => {
    let query = '';
    if (Object.keys(params).length) {
      query = `?${joinParams(params)}`;
    }
    try {
      const res = await request(`${url}${query}`, method, body, { signal: abortRef.current.signal });
      setResponse(res.data);
    } catch (e) {
      if (!abortRef.current.signal.aborted) {
        // assumption: loading is never set to false if error occurs
        setError(e);
      }
    }
  });

  useDeepCompareEffect(() => {
    if (!options.skip) {
      fetch();
    }
  }, [url, method, params, body]);

  return {
    response,
    error,
    loaded,
    loading,
    refresh: fetch,
  };
};

const useHttpGet = (url, params = {}, options = {}) => {
  return useHttp(url, 'GET', params, null, options);
};

const useHttpPost = (url, params, body, options = {}) => {
  return useHttp(url, 'POST', params, body, options);
};

export { useHttpGet, useHttpPost };
