import React from 'react';
import iconLoginSeaLogo from 'images/icon-login-sealogo@2x.png';
import styled from 'styled-components';
import { node } from 'prop-types';

const StyledContainer = styled.div`
  padding: 48px 16px 56px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledIconLoginSeaLogo = styled.img`
  height: 42px;
`;

export const StyledCardTitle = styled.h1`
  margin: 56px 0 0 0;
  text-align: center;
`;

function Container({ children }) {
  return (
    <StyledContainer>
      <StyledIconLoginSeaLogo src={iconLoginSeaLogo} alt="Sea Logo" />
      {children}
    </StyledContainer>
  );
}

Container.propTypes = {
  children: node.isRequired,
};

export default Container;
