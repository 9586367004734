import React from 'react';
import { node, object } from 'prop-types';
import styled from 'styled-components';
import ErrorBoundary from 'components/ErrorBoundary';
import ClientTemplate from 'components/templates/ClientTemplate';
import { HttpError } from 'utils/errors';

const StyledContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
`;

const StyledContent = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

function Message({ error }) {
  if (error instanceof HttpError) {
    return <h1>{error.response.status}</h1>;
  }
  return <p>{`${error}`}</p>;
}

Message.propTypes = {
  error: object,
};

function Fallback({ error }) {
  return (
    <ClientTemplate>
      <StyledContainer>
        <StyledContent>
          <h1>Oops, something went wrong...</h1>
          <Message error={error} />
        </StyledContent>
      </StyledContainer>
    </ClientTemplate>
  );
}

Fallback.propTypes = {
  error: object,
};

function GeneralErrorBoundary({ children }) {
  return <ErrorBoundary renderFallback={({ error }) => <Fallback error={error} />}>{children}</ErrorBoundary>;
}

GeneralErrorBoundary.propTypes = {
  children: node,
};

export default GeneralErrorBoundary;
