import React from 'react';
import styled from 'styled-components';

import { useThrowableHttpGet } from 'hooks/useThrowableHttp';
import { useLocation } from 'react-router-dom';
import LoginBtn from './LoginButton';
import { clientPropTypes } from './propTypes';

const StyledButtons = styled.div`
  margin-top: 58px;
  display: flex;
  flex-direction: column;
`;

function LoginButtons({ client }) {
  const params = new URLSearchParams(useLocation().search);
  const redirectUri = params.get('redirect_uri');
  const state = params.get('state');
  const { response } = useThrowableHttpGet(`/api/v2/clients/${client.clientId}/providers/`, { redirectUri, state });
  return (
    response && (
      <StyledButtons>
        {response.map(provider => (
          <LoginBtn key={provider.name} provider={provider} client={client} />
        ))}
      </StyledButtons>
    )
  );
}

LoginButtons.propTypes = {
  client: clientPropTypes,
};

export default LoginButtons;
