import React from 'react';
import { node } from 'prop-types';
import { FetchError } from 'utils/errors';
import ErrorBoundary from 'components/ErrorBoundary';
import { StyledCardTitle } from './Container';

function ClientErrorFallback({ error }) {
  if (error instanceof FetchError) {
    return (
      <>
        <StyledCardTitle>Oops, something went wrong...</StyledCardTitle>
        <h1>{error.code}</h1>
        <p>{error.message}</p>
      </>
    );
  }

  throw error;
}

function MErrorBoundary({ children }) {
  return (
    <ErrorBoundary renderFallback={({ error }) => <ClientErrorFallback error={error} />}>{children}</ErrorBoundary>
  );
}

MErrorBoundary.propTypes = {
  children: node.isRequired,
};

export default MErrorBoundary;
