import React, { useState } from 'react';
import TextField from '@seaweb/coral/components/TextField';
import Field from '@seaweb/coral/components/Field';
import Button from '@seaweb/coral/components/Button';
import styled from 'styled-components';
import iconLoginExmail from 'images/icon-login-exmail@2x.png';
import { useTranslation } from 'react-i18next';
import { useThrowableHttpGet } from 'hooks/useThrowableHttp';
import { string } from 'prop-types';
import useFetch from 'hooks/useFetch';
import { useLocation } from 'react-router-dom';
import { clientPropTypes } from '../Client/propTypes';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
  align-items: center;
  border-bottom: solid 1px #e3e8f6;
  background-color: rgba(0, 0, 0, 0.03);
`;

const QQLogo = styled.img`
  height: 42px;
`;

const StyledError = styled.p`
  color: rgb(247, 69, 69);
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
  align-items: center;
`;

function getFormData(encodedState, email, password) {
  const formData = new FormData();
  formData.append('email_address', email);
  formData.append('password', password);
  formData.append('state', encodedState);
  return formData;
}

function Footer({ encodedState, email, password }) {
  const { t } = useTranslation();
  const [errorLogin, setErrorLogin] = useState(null);
  const { request: loginRequest, loading } = useFetch();
  const login = async () => {
    if (loading) {
      return;
    }
    setErrorLogin(null);
    try {
      const formData = getFormData(encodedState, email, password);
      const payload = await loginRequest('/api/v2/custom/qq/auth/', 'POST', null, { body: formData });
      window.location = payload.data;
    } catch (exc) {
      setErrorLogin(exc);
    }
  };
  return (
    <>
      {errorLogin && <StyledError>{`${errorLogin}`}</StyledError>}
      <Button disabled={loading} onClick={login}>
        {t('client.qq.login')}
      </Button>
    </>
  );
}

Footer.propTypes = {
  encodedState: string.isRequired,
  email: string.isRequired,
  password: string.isRequired,
};

function QQLogin({ client }) {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const params = new URLSearchParams(useLocation().search);
  const redirectUri = params.get('redirect_uri');
  const state = params.get('state');
  const { response: encodedData } = useThrowableHttpGet(
    `/api/v2/clients/${client.clientId}/providers/qq/encoded_data/`,
    {
      redirectUri,
      state,
    }
  );
  return (
    encodedData && (
      <Container>
        <TitleContainer>
          <QQLogo src={iconLoginExmail} alt="QQ Logo" />
          <h1>Exmail</h1>
        </TitleContainer>
        <BodyContainer>
          <Field label={t('client.qq.email')}>
            <TextField fullWidth value={email} onChange={e => setEmail(e.target.value)} />
          </Field>
          <Field label={t('client.qq.password')}>
            <TextField type="password" fullWidth value={password} onChange={e => setPassword(e.target.value)} />
          </Field>
          <Footer encodedState={encodedData.encodedState} email={email} password={password} />
        </BodyContainer>
      </Container>
    )
  );
}

QQLogin.propTypes = {
  client: clientPropTypes,
};

export default QQLogin;
