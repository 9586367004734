import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import { object } from 'prop-types';
import { clientPropTypes } from './propTypes';

const StyleLoginButton = styled.button`
  display: flex;
  padding: 12px;
  align-items: stretch;
  transition: 0.2s;
  border-radius: 2px;
  border: solid 1px #e3e8f6;
  background-color: #ffffff;
  &:not(:first-child) {
    margin-top: 24px;
  }
  &:hover {
    background: rgb(232, 240, 254);
  }
`;

const StyledLoginButtonIcon = styled.img`
  height: 20px;
  flex-shrink: 0;
  padding-right: 12px;
  border-right: solid 1px #e3e8f6;
`;

const StyledLoginButtonLabel = styled.p`
  margin: auto 18px;
  flex-grow: 1;
  text-align: left;
  font-size: 14px;
`;

function DefaultLoginBtn({ provider }) {
  const { t } = useTranslation();
  return (
    <StyleLoginButton
      onClick={() => {
        window.location.href = provider.url;
      }}
    >
      <StyledLoginButtonIcon src={provider.logo} alt={`${provider.label} Login Icon`} />
      <StyledLoginButtonLabel>{t('client.loginBtnDescription', { name: provider.label })}</StyledLoginButtonLabel>
    </StyleLoginButton>
  );
}

DefaultLoginBtn.propTypes = {
  provider: object,
};

function QQLoginBtn({ provider, client }) {
  const { t } = useTranslation();
  const params = new URLSearchParams(useLocation().search);
  const redirectUri = params.get('redirect_uri');
  const state = params.get('state');
  const history = useHistory();
  return (
    <StyleLoginButton
      onClick={() =>
        history.push(`/web/clients/${client.clientId}/custom/qq/?redirect_uri=${redirectUri}&state=${state}`)
      }
    >
      <StyledLoginButtonIcon src={provider.logo} alt="Exmail Login Icon" />
      <StyledLoginButtonLabel>{t('client.loginBtnDescription', { name: provider.label })}</StyledLoginButtonLabel>
    </StyleLoginButton>
  );
}

QQLoginBtn.propTypes = {
  provider: object,
  client: clientPropTypes,
};

function LoginBtn({ provider, client }) {
  switch (provider.name) {
    case 'qq':
      return <QQLoginBtn provider={provider} client={client} />;
    default:
      return <DefaultLoginBtn provider={provider} />;
  }
}

LoginBtn.propTypes = {
  provider: object,
  client: clientPropTypes,
};

export default LoginBtn;
