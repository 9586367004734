/* eslint-disable max-classes-per-file */

/**
 * @enum
 */
export const StatusCodes = {
  Success: 200,
  BadRequest: 400,
  Unauthorized: 401,
  PermissionDenied: 403,
};

export class FetchError extends Error {
  constructor(response) {
    super();

    this.originalResponse = response;
    this.code = response.statusCode;
    this.message = response.payload;
    this.name = 'FetchError';
  }

  toString() {
    return this.message;
  }
}

export class HttpError extends Error {
  constructor(response) {
    super();
    this.response = response;
  }
}
