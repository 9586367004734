import React from 'react';
import styled from 'styled-components';
import imgLoginBottomLeft from 'images/img-login-bottomleft@2x.png';
import imgLoginUpperRight from 'images/img-login-upperright@2x.png';
import { node } from 'prop-types';

const Template = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const ImgLoginUpperRight = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  max-height: 134px;
  max-width: 100%;
  z-index: -1;
`;

const ImgLoginBottomLeft = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
  max-height: 244px;
  max-width: 100%;
  z-index: -1;
`;

function ClientTemplate({ children }) {
  return (
    <Template>
      {children}
      <ImgLoginUpperRight src={imgLoginUpperRight} />
      <ImgLoginBottomLeft src={imgLoginBottomLeft} />
    </Template>
  );
}

ClientTemplate.propTypes = {
  children: node.isRequired,
};

export default ClientTemplate;
